<template>
  <div id="login">
    <p style="text-align: center">用户名：admin;密码：12345678</p>
    <a-form-model
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      v-bind="layout"
    >
      <a-form-model-item has-feedback label="用户名" prop="name">
        <a-input
          v-model="ruleForm.name"
          type="text"
          autocomplete="off"
          placeholder="admin"
        />
      </a-form-model-item>
      <a-form-model-item has-feedback label="密码" prop="checkPass">
        <a-input
          v-model="ruleForm.checkPass"
          type="password"
          autocomplete="off"
          placeholder="12345678"
        />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="submitForm('ruleForm')">
          提交
        </a-button>
        <a-button style="margin-left: 10px" @click="resetForm('ruleForm')">
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import CryptoJS from 'crypto-js'
export default {
  data () {
    return {
      ruleForm: {
        name: '',
        checkPass: '',
        token: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入用户名admin', trigger: 'change' }
        ],
        checkPass: [
          { required: true, message: '请输入密码12345678', trigger: 'change' }
        ]
      },
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 }
      }
    }
  },
  methods: {
    // setTokenAction是获取并设置token方法
    ...mapActions(['setTokenAction']),
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            this.ruleForm.name === 'admin' &&
            this.ruleForm.checkPass === '12345678'
          ) {
            this.setTokenAction({
              name: 'admin',
              pass: '12345678'
            }).then((res) => {
              // 跳转到sso获取token
              // if (!this.$store.state.token) {
              //   this.$store.dispatch('getToken', window.location.href)
              // }

              this.$router.push({ path: '/layout' })
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    // AES解密
    decrypt (word) {
      // 密钥
      const privateKey = CryptoJS.enc.Utf8.parse('21436587A9CBEDF0')
      // 密钥偏移量
      const iv = CryptoJS.enc.Utf8.parse('DEBC9A785634120F')
      let encryptedHexStr = CryptoJS.enc.Hex.parse(word)
      let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)
      let decrypt = CryptoJS.AES.decrypt(srcs, privateKey, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })
      let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
      return decryptedStr.toString()
    },
    // 保存token到sessionStorage
    saveToken (TK) {
      // 解密token
      let token = this.decrypt(TK)
      // 保存到sessionStorage里面
      this.$store.commit('SETTOKEN', token)
      sessionStorage.setItem('token', token)
    }
  },
  created () {
    // 判断query中是否有从sso获取的token
    if (this.$route.query.TK && sessionStorage.getItem('userName')) {
      const { TK, ID } = this.$route.query

      this.saveToken(TK)
      // window.sessionStorage.setItem('token', token)
      this.$router.push({ path: '/layout' })
    }
  }
}
</script>
<style scoped>
#login {
  width: 500px;
  margin: 200px auto;
}
</style>
